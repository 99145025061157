import '../../../node_modules/react-modal-video/scss/modal-video.scss';

import React, { Component } from 'react';

import ModalVideo from 'react-modal-video';

//Importing Modal



class ModalSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.openModal.bind(this);
  }

  openModal() {
    this.setState({ isOpen: true });
  }

  render() {
    return (
      <React.Fragment>
        <ModalVideo
          channel={this.props.channel}
          isOpen={this.state.isOpen}
          videoId={this.props.videoId}
          onClose={() => this.setState({ isOpen: false })}
        />
      </React.Fragment>
    );
  }
}

export default ModalSection;
