import { Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';

import FeatureBox from './feature-box';
import SectionTitle from '../common/section-title';
import feature1 from '../../assets/images/features/fishin-carava-feature-1.png';
import feature2 from '../../assets/images/features/fishin-carava-feature-2.png';

class Features extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rfqFeatures: [
        { title: 'Direct Supplier Contact' },
        { title: 'Price Comparison' },
      ],
      supplychainFeatures: [
        { title: 'Real Time Updates' },
        { title: 'Track Supplier Performance' },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className='section' id='features'>
          <Container>
            <SectionTitle
              subtitle='Features'
              title='Key features of the product'
              //   desc='It will be as simple as occidental in fact, it will be Occidental.'
            />

            <Row>
              <Col lg={5}>
                <div>
                  <FeatureBox
                    icon='bar-chart-2'
                    title='Streamline the RFQ Process'
                    smallFeatures={this.state.rfqFeatures}
                    desc='Efficiently invite suppliers to RFQ and analyze supplier bids in one location.'
                    link='https://app.carava.com'
                  />
                </div>
              </Col>

              <Col lg={5} sm={8} className='ml-lg-auto'>
                <Card className='border border-light shadow-none mt-5 mt-lg-0'>
                  <CardHeader className='border-0 bg-transparent'>
                    <div>
                      <i className='mdi mdi-circle text-danger mr-1'></i>
                      <i className='mdi mdi-circle text-warning mr-1 ml-1'></i>
                      <i className='mdi mdi-circle text-success mr-1 ml-1'></i>
                    </div>
                  </CardHeader>
                  <CardBody className='bg-light'>
                    <div className='box-shadow'>
                      <img
                        src={feature1}
                        alt=''
                        className='img-fluid mx-auto d-block'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row className='mt-5 pt-5'>
              <Col lg={5} sm={8}>
                <Card className='border border-light shadow-none'>
                  <CardHeader className=' border-0 bg-transparent'>
                    <div>
                      <i className='mdi mdi-circle text-danger mr-1'></i>
                      <i className='mdi mdi-circle text-warning mr-1 ml-1'></i>
                      <i className='mdi mdi-circle text-success mr-1 ml-1'></i>
                    </div>
                  </CardHeader>
                  <CardBody className='bg-light'>
                    <div className='box-shadow'>
                      <img
                        src={feature2}
                        alt=''
                        className='img-fluid mx-auto d-block'
                      />
                    </div>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={5} className='ml-lg-auto'>
                <div className='mt-4 mt-lg-0'>
                  <FeatureBox
                    icon='pie-chart'
                    title='Increase supply chain visibility'
                    smallFeatures={this.state.supplychainFeatures}
                    desc='Track new inventory procured from RFQ through production, until delivery'
                    link='https://app.carava.com'
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Features;
