import {
  Button,
  Collapse,
  Container,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  NavbarBrand,
  NavbarToggler,
} from 'reactstrap';
import React, { Component } from 'react';

import ScrollspyNav from './scrollSpy';
import logodark from '../../assets/images/logo-dark.png';
import logolight from '../../assets/images/logo-light.png';

class NavbarPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpenMenu: false,
    };
  }

  toggle = () => {
    this.setState({ isOpenMenu: !this.state.isOpenMenu });
  };

  navigateToApp = () => {
    window.location = 'https://app.carava.com';
  };

  render() {
    //Store all Navigationbar Id into TargetID variable(Used for Scrollspy)
    let targetId = this.props.navItems.map((item) => {
      return item.idnm;
    });
    return (
      <React.Fragment>
        <Navbar
          expand='lg'
          fixed='top'
          className={'navbar-custom sticky sticky-dark ' + this.props.navClass}
        >
          <Container>
            <NavbarBrand className='logo text-uppercase' href='/'>
              {this.props.imglight === true ? (
                <img
                  src={logolight}
                  alt=''
                  className='logo-light'
                  height='40'
                />
              ) : (
                <img src={logodark} alt='' className='logo-dark' height='40' />
              )}
            </NavbarBrand>
            <NavbarToggler onClick={this.toggle}>
              <i className='mdi mdi-menu'></i>
            </NavbarToggler>

            <Collapse id='navbarCollapse' isOpen={this.state.isOpenMenu} navbar>
              <ScrollspyNav
                scrollTargetIds={targetId}
                scrollDuration='200'
                headerBackground='true'
                activeNavClass='active'
                className='navbar-collapse'
              >
                <Nav navbar className='ml-auto navbar-center' id='mySidenav'>
                  {this.props.navItems.map((item, key) => (
                    <NavItem
                      key={key}
                      className={item.navheading === 'Home' ? 'active' : ''}
                    >
                      <NavLink href={'#' + item.idnm}>
                        {' '}
                        {item.navheading}
                      </NavLink>
                    </NavItem>
                  ))}
                </Nav>
                <Button
                  color='success'
                  className='btn-rounded navbar-btn'
                  onClick={this.navigateToApp}
                >
                  Login
                </Button>
              </ScrollspyNav>
            </Collapse>
          </Container>
        </Navbar>
      </React.Fragment>
    );
  }
}

export default NavbarPage;
