import React, { Component } from 'react';

import Features from '../../components/Features/feature';
import Footer from '../../components/Footer/footer';
import NavbarPage from '../../components/Navbar/Navbar_Page';
import Section from './section';
import Service from '../../components/Service/service';
import Subscribe from '../../components/Subscribe/Subscribe';

class Index6 extends Component {
  constructor(props) {
    super(props);
    this.state = {
      navItems: [
        { id: 1, idnm: 'home', navheading: 'Home' },
        { id: 2, idnm: 'services', navheading: 'Pillars' },
        { id: 3, idnm: 'features', navheading: 'Features' },
        // { id: 4, idnm: 'pricing', navheading: 'Plans' },
      ],
      pos: document.documentElement.scrollTop,
      imglight: true,
      navClass: 'navbar-light',
      isMobile: window.innerWidth <= 768,
    };
  }

  componentDidMount() {
    if (!this.state.isMobile)
      window.addEventListener('scroll', this.scrollNavigation, true);
    else
      this.setState({ navClass: 'navbar-light nav-sticky', imglight: false });
  }

  componentWillUnmount() {
    if (!this.state.isMobile)
      window.removeEventListener('scroll', this.scrollNavigation, true);
  }

  scrollNavigation = () => {
    var scrollup = document.documentElement.scrollTop;
    if (scrollup > this.state.pos) {
      this.setState({ navClass: 'navbar-light nav-sticky', imglight: false });
    } else {
      this.setState({ navClass: 'navbar-light', imglight: true });
    }
  };

  render() {
    return (
      <React.Fragment>
        {/* Importing Navbar */}
        <NavbarPage
          navItems={this.state.navItems}
          navClass={this.state.navClass}
          imglight={this.state.imglight}
        />

        {/* section */}
        <Section />

        {/* Service */}
        <Service />

        {/* Features */}
        <Features />

        {/* Importing Pricing */}
        {/* <Pricing /> */}

        {/* Importing Sunscribe */}
        {/* <Subscribe /> */}

        {/* Importing Footer */}
        <Footer />
      </React.Fragment>
    );
  }
}

export default Index6;
