import { Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';

import { Link } from 'react-router-dom';
import SectionTitle from '../common/section-title';
import ServiceBox from './services-box';

class Process extends Component {
  constructor(props) {
    super(props);
    this.state = {
      services: [
        {
          icon: 'shield',
          title: 'Supply Chain Transparency',
          desc: 'Comprehensive visibility to supply chain status from origin facilities to destination warehouse',
        },
        {
          icon: 'clipboard',
          title: 'RFQ Management',
          desc: 'Efficient execution of bid consolidation, negotitation and finalization of awards',
        },
        {
          icon: 'layers',
          title: 'End-to-End Traceability',
          desc: 'Product traceability to processing facilities and raw material sources',
        },
        {
          icon: 'file',
          title: 'Document Management',
          desc: 'Centrally housed documentation for orders and facilities',
        },
        {
          icon: 'truck',
          title: 'Purchase Order Tracking',
          desc: 'Live container and production tracking to support forecasting accuracy and ongoing execution',
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <section className='section bg-light' id='services'>
          <Container>
            <SectionTitle
              subtitle='Pillars'
              title='Pillars of Carava'
              desc='Eliminate any obscurity in your procurement process​'
            />

            <Row style={{ justifyContent: 'space-evenly' }}>
              <ServiceBox services={this.state.services} />
            </Row>

            <Row className='mt-4'>
              <Col lg={12}>
                <div className='text-center'>
                  <Link to='#' className='btn btn-success'>
                    View more
                  </Link>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Process;
