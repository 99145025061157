import { Col, Container, Media, Row } from 'reactstrap';
import React, { Component } from 'react';

import FeatherIcon from 'feather-icons-react';
import FooterLinks from './footer-links';
import { Link } from 'react-router-dom';

//Import Footer link

class Footer extends Component {
  state = {
    links1: [
      { link: '#', title: 'About Us' },
      { link: '#', title: 'Leadership' },
      { link: '#', title: 'Career' },
      { link: '#', title: 'Blog' },
    ],
    links2: [
      { link: '#', title: 'Importers' },
      { link: '#', title: 'Processing plants' },
      { link: '#', title: 'Auctions' },
      { link: '#', title: 'Proforma Handling' },
      { link: '#', title: 'Reports' },
    ],
  };
  render() {
    return (
      <React.Fragment>
        <footer className='footer'>
          <Container>
            <Row>
              <Col lg={4} sm={6}>
                <div>
                  <h5 className='mb-4 footer-list-title'>About Carava</h5>
                  <p>
                    Carava streamlines the procurement process through a
                    consolidated platform, facilitating direct and transparent
                    communication between buyers and sellers.
                  </p>
                </div>
              </Col>
              <Col lg={{ size: 2, offset: 1 }} sm={6}>
                <div>
                  <h5 className='mb-4 footer-list-title'>Company</h5>
                  <ul className='list-unstyled footer-list-menu'>
                    {this.state.links1.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
              <Col lg={2} sm={6}>
                <div>
                  <h5 className='mb-4 footer-list-title'>Explainer Videos</h5>
                  <ul className='list-unstyled footer-list-menu'>
                    {this.state.links2.map((fLink, key) => (
                      <li key={key}>
                        <Link to={fLink.link}>{fLink.title}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>

              <Col lg={3} sm={6}>
                <div>
                  <h5 className='mb-4 footer-list-title'>Contact</h5>

                  <div>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon='map-pin'
                          className='icon-dual-light icons-sm mt-1 mr-2'
                        />
                      </i>
                      <Media body>
                        <p>5111 JB Hunt Dr., Suite 500, Rogers, AR 72758 </p>
                      </Media>
                    </Media>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon='mail'
                          className='icon-dual-light icons-sm mt-1 mr-2'
                        />
                      </i>
                      <Media body>
                        <p>support@carava.com</p>
                      </Media>
                    </Media>
                    <Media>
                      <i>
                        <FeatherIcon
                          icon='phone'
                          className='icon-dual-light icons-sm mt-1 mr-2'
                        />
                      </i>
                      <Media body>
                        <p>1.412.464.9000</p>
                      </Media>
                    </Media>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </footer>
        {/* Render footer links */}
        <FooterLinks />
      </React.Fragment>
    );
  }
}

export default Footer;
