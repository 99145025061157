import { Col, Container, Row } from 'reactstrap';
import React, { Component } from 'react';

import ModalSection from '../../components/common/ModalSection';

class Section extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
    };
    this.callModal.bind(this);
  }

  callModal = () => {
    this.refs.child.openModal();
  };

  render() {
    return (
      <React.Fragment>
        <section className='hero-section-2 bg-primary' id='home'>
          <Container>
            <Row className='justify-content-center'>
              <Col lg={8}>
                <div className='hero-wrapper text-center mb-4'>
                  <p className='font-16 text-white-50 text-uppercase'>
                    Discover Carava Today
                  </p>
                  <h1 className='hero-title text-white mb-4'>
                    Simplify your seafood sourcing with{' '}
                    <span className='text-success'>Carava</span>
                  </h1>

                  <p className='text-white-50'>
                    Carava streamlines the procurement process through a
                    consolidated platform, facilitating direct and transparent
                    communication between buyers and sellers.
                  </p>

                  <div className='watch-video pt-5'>
                    {/* <Link
                      onClick={this.callModal}
                      to='#'
                      className='video-play-icon text-light'
                    >
                      <i className='mdi mdi-play play-icon-circle play play-iconbar'></i>{' '}
                      WATCH VIDEO
                    </Link> */}
                  </div>
                </div>
              </Col>
            </Row>
            {/* Render ModalSection Component for Modal */}
            <ModalSection ref='child' channel='vimeo' videoId='99025203' />
          </Container>
        </section>
      </React.Fragment>
    );
  }
}

export default Section;
